<template>
  <div v-if="!largeMode || promotions.length > 2" class="mb-2">Select amount:</div>
  <div v-if="promotionsLoader" class="flex justify-center w-full text-center my-32">
    <loading-icon :size="6" thickness="xs"/>
  </div>
  <div v-else class="w-full mb-4">
    <select v-if="!largeMode || promotions.length > 2" v-model="amount" placeholder="Select" value-key="amount"
            data-cy="addfunds-select" class="cust-select text-b-m mx-0 w-100 !p-12">
      <option value="" selected hidden>Select</option>
      <template v-for="item in promotions" :key="item.amount">
        <option
          :value="(item.off ? item : item.amount)" :class="{ 'is-discount': item.off }"
          :data-cy="'addfunds-item-' + item.amount"
        >
                    <span v-if="item.off">
                        {{ item.off }}% OFF - ${{
                        item.amount
                      }} <b>(Save ${{ Math.round(item.amount * item.off / 100, 1) }})</b>
                    </span>
          <span v-else-if="item.minutes">
                        {{ item.minutes }} minutes - ${{ Number(item.amount).toFixed(2) }}
                    </span>
          <span v-else>
                        ${{ item.amount }}
                    </span>
        </option>
      </template>
    </select>
    <div v-else-if="singleMode && minutes"
         class="cards-container large-payment-options text-center justify-content-center mb-5">
      <template v-for="item in promotions" :key="item.amount">
        <div v-if="amount == (item.off ? item : item.amount)">
          <label
            :class="{ 'is-discount': item.off, 'selected': amount == (item.off ? item : item.amount) }"
            class="mb-4 py-4 px-2 mx-2 large-payment-option text-center"
            :data-cy="'addfunds-item-' + item.amount"
            :for="'addfunds-item-' + item.amount"
          >
            <input
              type="radio"
              group="add-funds-buttons"
              v-model="amount"
              :id="'addfunds-item-' + item.amount"
              :value="(item.off ? item : item.amount)"
              class="hidden"
            />

            <div class="d-md-flex flex-row-reverse align-items-center justify-content-around">
              <div>
                <h4 class="small">
                  {{ advisor.default_free_minutes }} FREE minutes
                  <span v-if="advisor.discount">& {{ advisor.discount }}% off</span>
                  +
                </h4>
                <h3>
                  {{ item.minutes }} minutes
                </h3>
              </div>

              <div class="mt-4 mt-md-0">
                <div>
                  ${{ Number(item.amount).toFixed(2) }}
                  <span class="old-price" v-if="advisor?.discount">
                                        ${{ (item.amount * 100 / Math.max(1, 100 - advisor.discount)).toFixed(2) }}
                                    </span>
                </div>
                <!-- <button class="btn btn-primary mt-3">Selected</button> -->
                <se-button
                  size="small"
                  type="primary"
                >
                  Selected
                  <se-icon name="mail" :size="18" class="mr-6"/>
                </se-button>
              </div>
            </div>
          </label>
        </div>
      </template>
    </div>
    <div v-else class="cards-container flex large-payment-options justify-content-center">
      <template v-for="item in promotions" :key="item.amount">
        <label
          :class="{ 'is-discount': item.off, 'selected': amount == (item.off ? item : item.amount) }"
          class="mb-4 p-16 large-payment-option text-center"
          :data-cy="'addfunds-item-' + item.amount"
          :for="'addfunds-item-' + item.amount"
        >
          <input
            type="radio"
            group="add-funds-buttons"
            v-model="amount"
            :id="'addfunds-item-' + item.amount"
            :value="(item.off ? item : item.amount)"
            class="hidden"
          />

          <div class="text-b-l font-semibold">
                        <span v-if="item.off"> 
                            {{ item.off }}% OFF - ${{
                            item.amount
                          }} <b>(Save ${{ Math.round(item.amount * item.off / 100, 1) }})</b>
                        </span>
            <span v-else-if="item.minutes">
                            {{ item.minutes }} minutes <!-- - ${{ Number(item.amount).toFixed(2) }} -->
                        </span>
            <span v-else>
                            ${{ Number(item.amount).toFixed(2) }}
                        </span>
          </div>

          <div
            class="inline-block bg-primary-10 text-primary-140 text-b-xxs font-semibold rounded-[4px] px-8 py-[2px] mt-8">
            + {{ advisor.default_free_minutes }} FREE
            <span v-if="advisor?.discount" class="free-minutes">
                            &amp; {{ advisor.discount }}% OFF
                        </span>
          </div>

          <div class="h-[0.5px] w-full bg-neutral-30 my-16"></div>

          <div class="flex flex-col items-center mb-16">
            <p class="old-price" v-if="advisor?.discount">
              ${{ (item.amount * 100 / Math.max(1, 100 - advisor.discount)).toFixed(2) }}</p>
            <p class="new-price">${{ Number(item.amount).toFixed(2) }}</p>
          </div>

          <se-button
            class="w-[104px]"
            size="small"
            :type="amount === (item.off ? item : item.amount) ? 'primary' : 'tertiary'"
            @click="amount = (item.off ? item : item.amount)"
          >
            {{ amount === (item.off ? item : item.amount) ? 'Selected' : 'Select' }}
            <se-icon v-if="amount === (item.off ? item : item.amount)" name="check" :size="16" class="ml-6"/>
          </se-button>
        </label>
      </template>
    </div>
  </div>
  <se-payment-method :amount="amount" :redirect-url="redirectUrl" :advisor-id="advisorId"/>
</template>

<script>
import {mapState, mapActions} from 'pinia'
import {usersStore} from '/src/store/users'
import config from '/config'
import SeButton from '../shared/ui-kit/button/Button.vue';
import SeIcon from '../shared/ui-kit/icon/Icon.vue';
import SePaymentMethod from '../modules/payment/components/payment-method.vue';
import LoadingIcon from './LoadingIcon.vue';

export default {
  props: {
    redirectUrl: {
      type: String,
      required: false,
      default: 'home',
    },
    advisorId: {
      type: Number,
      required: false,
      default: 0,
    },
    chatMode: {
      type: String,
      required: false,
      default: '',
    },
    defaultToFirst: {
      type: Boolean,
      required: false,
      default: false,
    },
    largeMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    singleMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    minutes: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      amount: "",
      stripeEnabled: config.stripe.enabled,
      paypalEnabled: config.paypal.enabled,
      advisor: {},
      promotionsLoader: true,
    }
  },
  components: {
    SeButton,
    SeIcon,
    SePaymentMethod,
    LoadingIcon,
  },
  watch: {
    advisorId() {
      if (this.advisorId) {
        // Reloading promotions if having advisor specified.
        this.updatePromotions()
        this.getAdvisor()
      }
    },
  },
  mounted() {
    this.updatePromotions()
    this.getAdvisor()
  },
  methods: {
    ...mapActions(usersStore, [
      'submitAddFunds',
      'getPromotions',
      'getUserById',
      'getFreeMinutes',
    ]),
    async updatePromotions() {
      this.promotionsLoader = true;
      await this.getPromotions(this.advisorId, this.chatMode)
      this.promotionsLoader = false;

      if (this.largeMode && this.singleMode && this.minutes.length) {
        for (let promotionId in this.promotions) {
          if (this.promotions[promotionId].minutes == this.minutes) {
            this.amount = this.promotions[promotionId].amount
          }
        }
      }

      if (!this.largeMode && this.chargeAmount && this.fundsNeeded > 0) {
        const setPromotion = promotion => this.amount = promotion.off ? promotion : promotion.amount

        // Set amount to the first promotion which covers the needed funds
        for (let promotionId in this.promotions) {
          if (this.promotions[promotionId].amount >= this.fundsNeeded) {
            setPromotion(this.promotions[promotionId])

            break
          }
        }

        // If needed funds still exceed all the promotions, then select the last one
        if (!this.amount) {
          setPromotion(this.promotions[this.promotions.length - 1])
        }
      } else if (!this.amount || !this.singleMode) {
        if (this.defaultToFirst) {
          this.amount = this.promotions[0].amount
        } else {
          for (let promotionId in this.promotions) {
            // console.log("promotionId", promotionId)
            if (this.promotions[promotionId].default) {
              this.amount = this.promotions[promotionId].amount
            }
          }
        }
      }
    },
    async getAdvisor() {
      if (this.advisorId) {
        this.advisor = await this.getUserById(this.advisorId)
      }
    },
    freeMinutes() {
      return this.getFreeMinutes(this.advisor)
    },
  },
  computed: {
    ...mapState(usersStore, [
      'currentUser',
      'addFundsLoading',
      'promotions',
      'chargeAmount',
    ]),
    fundsNeeded() {
      return this.chargeAmount > 0 ? Math.abs(Number(this.currentUser.amount) - this.chargeAmount) : 0;
    },
  },
}
</script>

<style lang="scss" scoped>
@import "/src/styles/variables";

.cards-container {
  @apply w-full;
}

.large-payment-options {
  @apply gap-24;
}

.large-payment-option {
  @apply rounded-12 w-full border border-neutral-30;
}

.large-payment-options .large-payment-option .btn-primary {
  display: none;
}

.large-payment-options .large-payment-option .btn-outline-dark {
  display: inline-block;
}

.large-payment-options .large-payment-option.selected .btn-primary {
  display: inline-block;
}

.large-payment-options .large-payment-option.selected .btn-outline-dark {
  display: none;
}

.large-payment-options .large-payment-option.selected {
  @apply border-primary-100 border-2;
}

.large-payment-option {
  .old-price {
    @apply text-b-s font-semibold text-neutral-50 line-through;
  }

  .new-price {
    @apply text-h-xl font-bold text-primary-100;
  }
}
</style>