<template>
  <div class="w-full">
    <div v-if="$route.path.includes('/get-started')" class="text-b-s text-neutral-80 mb-24 mr-[20%]">
      If you're satisfied, continue by making a payment. If not, you have the option to change advisors using the funds you have left.
    </div>
    <!-- <div v-else class="text-b-s text-neutral-60 mb-8">
      You will be able to pay with your card via PayPal.
    </div> -->

    <div v-if="stripeEnabled" class="mb-4">
      <button
        type="button"
        class="btn d-block mb-2 btn-stripe bg-neutral-80"
        :disabled="!amount"
        :class="{ 'is-loading': addFundsLoading, 'btn-stripe': !currentUser.stripe_in_usd }"
        data-cy="addfunds-button"
        @click="submitAddFunds('stripe', advisorId, amount, finalRedirectUrl)"
      />
      <div class="d-flex justify-content-end mb-3">
        <img
          v-if="true || !currentUser.stripe_in_usd"
          src="/static/images/Stripe.png"
          class="float-right mr-3"
          width="60"
          height="20"
          alt="payment method img"
        />
        <img
          src="/static/images/payment-cards.png"
          class="float-right"
          width="120"
          height="20"
          alt="cards"
        />
      </div>
    </div>

    <div v-if="paypalEnabled" class="mb-4">
      <p class="font-size-small">
        You can pay with your normal credit card via PayPal
      </p>
      <button
        type="button"
        class="btn btn-paypal d-block mx-0"
        :disabled="!amount"
        :class="{ 'is-loading': addFundsLoading }"
        data-cy="addfunds-button"
        @click="submitAddFunds('paypal', advisorId, amount, finalRedirectUrl)"
      />
      <div class="text-black text-b-xs mt-[4px]">
        <span class="font-size-small">The safer, easier way to pay</span>
        <img
          src="/static/images/paypal-cards.png"
          class="float-right"
          width="120"
          height="20"
          alt="cards"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { usersStore } from '../../../store/users';
import { getGlobalProperties } from '../../../shared/helpers/getGlobalProperties';
import config from '../../../../config';

const props = withDefaults(defineProps<{
    redirectUrl: string,
    advisorId: number,
    amount: number
}>(), {
  redirectUrl: 'home',
  advisorId: 0,
});

const stripeEnabled = config.stripe.enabled;
const paypalEnabled = config.paypal.enabled;

const { $route } = getGlobalProperties();

const user = usersStore();
const { submitAddFunds } = user;
const { addFundsLoading, savedRedirectUrl, currentUser } = storeToRefs(user);

const finalRedirectUrl = computed(() => savedRedirectUrl.value || props.redirectUrl);
</script>

<script lang="ts">
export default {
  name: 'SePaymentMethod',
};
</script>
